import fetcher from '@lib/fetcher'
import UAParser from 'ua-parser-js'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { getGaId } from '@lib/utils/tools'
import { isFromEu } from '@lib/hooks/useFromEu'

const NEXT_PUBLIC_ENV = process.env.NEXT_PUBLIC_ENV

const getProductPersonalizeByUser = async ({ locale, limit }) => {
  try {
    const fromEu = isFromEu({ locale })
    const host = fromEu
      ? NEXT_PUBLIC_ENV === 'production'
        ? 'https://rainbowbridge-eu.anker.com/api'
        : 'https://rainbowbridge-eu-next.anker.com/api'
      : NEXT_PUBLIC_ENV === 'production'
      ? 'https://rainbowbridge.anker.com/api'
      : 'https://rainbowbridge-next.anker.com/api'
    const parser = new UAParser()
    const userAgent = parser.getResult()
    const url = `${host}/v2/personalize/relation/by_user`
    const user_id = getGaId() || ''
    const result = await fetcher({
      url,
      locale,
      'Content-Type': 'application/json',
      method: 'GET',
      body: {
        shopify_domain: I18N_STORE_DOMAIN[locale],
        user_id,
        country: locale,
        limit,
        device_type: userAgent?.device?.type || userAgent?.os?.name,
        browser_type: userAgent?.browser?.name,
      },
    })
    if (!result?.errors && result?.data) {
      return result
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export default getProductPersonalizeByUser
