import { LOCALE_ASCRIPTION_MAP, EU_SHOP_LOCALES } from '@shopify/const'
import { useMemo } from 'react'

type Props = {
  locale?: string
}

export const isFromEu = ({ locale }: Props) => {
  const asLocale = LOCALE_ASCRIPTION_MAP[locale || ''] || locale
  return EU_SHOP_LOCALES.includes(asLocale || '')
}

const useFromEu = ({ locale }: Props) => {
  const fromEu = useMemo(() => {
    return isFromEu({ locale })
  }, [locale])

  return fromEu
}

export default useFromEu
